import React from 'react';
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

import withRouter from "./hooks/withRouter";
import App from "./App";
import {useFetchApplicationInfo} from "./hooks/query";
import {get} from "./config";

const AppWrapper = () => {
    const {data} = useFetchApplicationInfo();

    const stripeMode = get(`app::stripe.mode`);
    const stripeConfig = get(`app::stripe.${stripeMode}`);

    let publishableKey = data?.app?.stripe?.publishable_key ?? stripeConfig["publishable_key"];

    const [stripePromise, setStripePromise] = React.useState({
        [publishableKey]: loadStripe(publishableKey)
    });

    React.useEffect(() => {
        if (publishableKey && !stripePromise[publishableKey]) {
            setStripePromise((previousState) => {
                return {
                    ...previousState,
                    [publishableKey]: loadStripe(publishableKey)
                }
            })
        }
    }, [stripePromise, publishableKey]);

    if (!stripePromise[publishableKey]) {
        return (
            <App/>
        );
    }

    return (
        <Elements stripe={stripePromise[publishableKey]}>
            <App/>
        </Elements>
    );
}

export default withRouter(AppWrapper);