import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "../../axios";
import v2Axios from '../../axios/v2';
import {useDispatch} from "react-redux";
import * as actions from "../../store/actions";
import {getResponseErrorMessage} from "../../support/utility";

const FETCH_AUTH = "FETCH_AUTH";

const processAuthData = (data) => {
    if (!data.hasOwnProperty('user')) {
        data.user = data['user_info'] ?? null;
    }

    return data;
}

export const useFetchAuth = (props = {}) => {
    return useQuery(FETCH_AUTH, async () => {
        let response = await v2Axios.get("auth/info");
        return response.data;
    }, {
        enabled: false,
        ...props
    });
}

export const useLogin = (props = {}) => {
    const queryClient = useQueryClient();

    return useMutation(async (data) => {
        return await axios.post("auth/login", data);
    }, {
        mutationKey: "LOGIN",
        ...props,
        onSuccess: (response) => {
            queryClient.setQueryData(FETCH_AUTH, processAuthData(response.data));
            if (props.hasOwnProperty('onSuccess')) {
                props.onSuccess(response);
            }
        },
    });
}

export const useLoginSocial = (props = {}) => {
    const queryClient = useQueryClient();

    return useMutation(async ({type, response}) => {
        let socialData

        switch (type) {
            case 'facebook':
                socialData = {
                    token: response.authResponse.accessToken
                }
                break;
            case 'apple':
                socialData = response.authorization;
                if (socialData && socialData['id_token']) {
                    socialData.token = socialData['id_token'];
                }
                break;
            case 'google':
                socialData = {
                    code: response.code ? response.code : null,
                    idToken: response.credential ? response.credential : null
                }
                break;
            default:
                socialData = response;
                break;
        }

        return await axios.post(`auth/login/social/${type}`, socialData);
    }, {
        mutationKey: "LOGIN_SOCIAL",
        ...props,
        onSuccess: (response) => {
            queryClient.setQueryData(FETCH_AUTH, processAuthData(response.data));

            if (props.hasOwnProperty('onSuccess')) {
                props.onSuccess(response);
            }
        },
    });
}

export const useForgetPassword = (props = {}) => {
    return useMutation(async (data) => {
        return await axios.post("auth/forget-password", data);
    }, {
        mutationKey: "FORGET_PASSWORD",
        ...props
    })
}

export const useResetPassword = (props = {}) => {
    return useMutation(async (data) => {
        return await axios.post("auth/reset-password", data);
    }, {
        mutationKey: "RESET_PASSWORD",
        ...props
    })
}

export const useRegister = (props = {}) => {
    const queryClient = useQueryClient();

    return useMutation(async (data) => {
        return await axios.post("auth/register", data);
    }, {
        mutationKey: "REGISTER",
        ...props,
        onSuccess: (response) => {
            queryClient.setQueryData(FETCH_AUTH, processAuthData(response.data));

            if (props.hasOwnProperty('onSuccess')) {
                props.onSuccess(response);
            }
        },
    })
}

export const useUpdatePassword = (props = {}) => {
    const queryClient = useQueryClient();

    return useMutation(async (data) => {
        return await v2Axios.post("auth/update-password", data);
    }, {
        mutationKey: "UPDATE_PASSWORD",
        ...props,
        onSuccess: (response) => {
            queryClient.setQueryData(FETCH_AUTH, (oldValue) => {
                return {
                    ...oldValue,
                    user: {
                        ...oldValue.user,
                        password_is_set: true
                    }
                };
            });

            if (props.hasOwnProperty('onSuccess')) {
                props.onSuccess(response);
            }
        },
    });
}

export const useLogout = (props = {}) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    return useMutation(async (data = {}) => {
        return await axios.post("auth/logout", data);
    }, {
        mutationKey: "LOGOUT",
        onSuccess: () => {
            queryClient.resetQueries(FETCH_AUTH, {exact: true}).then(() => {
            });
        },
        onError: (error) => {
            dispatch(actions.pushToastMessage({
                title: getResponseErrorMessage(error),
                type: 'error'
            }));
        },
        ...props
    });
}

export const useUpdateUserInfo = () => {
    const queryClient = useQueryClient();

    return {
        update: (data) => {
            queryClient.setQueryData(FETCH_AUTH, (oldValue) => {
                return {
                    ...oldValue,
                    user: {
                        ...oldValue.user,
                        ...data.user
                    }
                };
            });
        }
    }
}