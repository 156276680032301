import React from 'react';
import {ToastContainer} from "react-toastify";

import Master from "../../Master/Master";

import 'react-toastify/dist/ReactToastify.css';


const singleLayout = (props) => {
    return (
        <Master>
            <div className={"bg-default text-sm"}>
                <ToastContainer/>
                {props.children}
            </div>
        </Master>
    )
}

export default singleLayout;